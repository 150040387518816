import { useState } from 'react';
import { Button, Skeleton, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';
import { useAsyncRetry } from 'react-use';

import { UsageLogsInteractionApi } from 'src/api-new/bullis';
import { ListPagination } from 'src/components/organisms/List';
import { Logs } from 'src/containers/Dashboards/UsageLogs/Logs';
import { useBullisApi } from 'src/services/useBullisApi';
import { useInstances } from 'src/components/hooks/useInstances';

const LOGS_PER_PAGE = 10;

export function PipelineLogs({ pipelineId }: { pipelineId: string }) {
  const bullisApi = useBullisApi(UsageLogsInteractionApi);
  const { selectedInstance } = useInstances();
  const [currentPage, setCurrentPage] = useState(0);

  const [css, theme] = useStyletron();

  const logs = useAsyncRetry(async () => {
    const { data } = await bullisApi.allUsageLogsInteraction({
      usageLogAllBody: {
        instance_name: selectedInstance.name,
        module_name: 'bifrost',
        filters: [{ key: 'aggregate_identifier', exact_value: pipelineId, condition: 'exact' }],
        sort_by: 'timestamp',
        order_by: 'desc',
      },
    });
    return data.items;
  }, [selectedInstance]);

  return (
    <Wrapper direction="column" gap={200}>
      <Wrapper>
        <Button hasMargin kind="secondary" size="large" onClick={logs.retry}>
          Refresh
        </Button>
      </Wrapper>
      {logs.loading ? <Skeleton rows={LOGS_PER_PAGE} /> : <Logs logs={logs.value ?? []} />}
      <Wrapper paddingBlock={300} className={css({ backgroundColor: theme.colors.backgroundTertiary })}>
        <ListPagination
          className={css({ width: '200px' })}
          currentPageItemsCount={logs.value?.length ?? 0}
          skip={LOGS_PER_PAGE * currentPage}
          size={LOGS_PER_PAGE}
          disabled={logs.loading}
          onPrev={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
          onNext={() => setCurrentPage((prev) => prev + 1)}
        />
      </Wrapper>
    </Wrapper>
  );
}
