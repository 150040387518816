import { useEffect, useState } from 'react';

export function useReset<T>(initial: T, deps: Array<any>) {
  const [state, setState] = useState(initial);
  useEffect(() => setState(initial), [...deps]);
  return [state, setState] as const;
}

export interface Paginatable<T> {
  next(): void;
  previous(): void;
  reset(): void;
  unsafe_override: (index: number) => void;
  index: number;
  pages: number;
  slice: Array<T>;
}

export function usePagination<T>({ items, size }: { items: Array<T>; size: number }): Paginatable<T> {
  const [index, setIndex] = useState(0);
  const pages = Math.ceil(items.length / size);

  function next() {
    setIndex((prevIndex) => Math.min(prevIndex + 1, pages));
  }

  function previous() {
    setIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  }

  function reset() {
    setIndex(0);
  }

  function unsafe_override(index: number) {
    setIndex(index);
  }

  const slice = items.slice(index * size, (index + 1) * size);
  return {
    index: index + 1,
    slice,
    pages,
    next,
    unsafe_override,
    previous,
    reset,
  };
}

export function useDocumentPagination(
  documentSize: number,
  pageSize: number,
): Omit<Paginatable<unknown>, 'slice'> & { skip: number; pages: number } {
  const [skip, setSkip] = useReset(0, [documentSize]);
  const index = Math.floor(skip / pageSize) + 1;
  const pages = Math.ceil(documentSize / pageSize);

  function next() {
    setSkip((prevSkip) => {
      const newSkip = Math.min(prevSkip + pageSize, documentSize - (documentSize % pageSize || pageSize));
      return newSkip;
    });
  }

  function previous() {
    setSkip((prevSkip) => {
      const newSkip = Math.max(prevSkip - pageSize, 0);
      return newSkip;
    });
  }

  function unsafe_override(index: number) {
    const newSkip = Math.min(Math.max((index - 1) * pageSize, 0), documentSize - (documentSize % pageSize || pageSize));
    setSkip(newSkip);
  }

  function reset() {
    setSkip(0);
  }

  return {
    skip,
    index,
    pages,
    next,
    previous,
    reset,
    unsafe_override,
  };
}
