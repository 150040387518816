import { HeadingXSmall, Input, NoContent, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';
import { useState } from 'react';
import { useAsyncRetry } from 'react-use';

import { DbCollectionsApi } from 'src/api-new/bifrost';
import { useInstances } from 'src/components/hooks/useInstances';
import { useBifrostApi } from 'src/services/useBifrostApi';

import { useDebouncedValue, useSnackbar } from '@visualfabriq/mfe-utils';
import { getErrorMessage } from 'src/api-new/bifrost/utils';
import { Resizer } from 'src/components/atoms/Resizer';
import { usePagination } from 'src/components/hooks';
import { useResize } from 'src/components/hooks/useResize';
import { List, ListHeader, ListItem } from 'src/components/organisms/List';
import { Pagination } from 'src/components/organisms/List/ListPagination';
import { captureException } from 'src/services/sentry';
import { collectionListItemTestId, collectionListLoadingTestId } from './constants';

export function Collections() {
  const dbCollectionApi = useBifrostApi(DbCollectionsApi);
  const [, theme] = useStyletron();
  const { selectedInstance } = useInstances();
  const [search, setSearch] = useState('');
  const { value: searchDebounce } = useDebouncedValue(search, 500);
  const snackbar = useSnackbar();

  const collections = useAsyncRetry(async () => {
    try {
      const { data } = await dbCollectionApi.getCollections({
        instanceId: selectedInstance.id,
        nameQuery: search !== '' ? search : undefined,
      });
      return data.map(({ name }) => ({
        id: name,
        name: name,
      }));
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(getErrorMessage(error));
      return undefined;
    }
  }, [selectedInstance.id, searchDebounce]);

  const { index, pages, slice, next, previous } = usePagination({ items: collections.value ?? [], size: PAGE_SIZE });
  const [resizeRef, width] = useResize<HTMLDivElement>({ initialWidth: 250, minWidth: 150, maxWidths: 500 });

  return (
    <Wrapper marginInline={`0 ${theme.sizing.scale800}`}>
      <div style={{ width }}>
        <List
          heading={
            <Wrapper direction="column" gap="200" marginBlock={`0 ${theme.sizing.scale200}`}>
              <ListHeader onRefresh={collections.retry}>
                <HeadingXSmall marginBottom="0">Collections</HeadingXSmall>
              </ListHeader>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Collection"
                clearable
              />
            </Wrapper>
          }
          loaderTestId={collectionListLoadingTestId}
          loading={collections.loading}
          loadingItems={PAGE_SIZE}
          style={{ height: 'calc(100% - 42px)' }}
        >
          {slice.length ? (
            slice.map((item) => <ListItem key={item.id} item={item} testId={collectionListItemTestId} />)
          ) : (
            <NoContent message="No collections available" />
          )}
        </List>
        {pages > 1 && <Pagination index={index} pages={pages} next={next} previous={previous} />}
      </div>
      <Resizer ref={resizeRef} />
    </Wrapper>
  );
}

const PAGE_SIZE = 30;
