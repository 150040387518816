import { Pagination, Tokens, useSnackbar, Wrapper } from '@visualfabriq/vf-ui-kit';
import { useContext, useEffect, useState } from 'react';
import { useAsyncRetry } from 'react-use';

import { PipelineFilteredRequestBody, PipelinesApi, PipelineStepsApi } from 'src/api-new/bifrost';
import { useDebouncedValue } from 'src/components/hooks/useDebouncedValue';
import { useInstances } from 'src/components/hooks/useInstances';
import { useDrawer } from 'src/components/molecules/Drawer/useDrawer';
import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { getOrderedPipelineSteps } from 'src/domain/pipelines/getOrderedPipelineSteps';
import { PipelinesContext } from 'src/domain/pipelines/PipelinesProvider';
import { useBifrostApi } from 'src/services/useBifrostApi';

import { AddPipelineModal } from './components/AddPipelineModal';
import { CrateUsingTemplateDrawer } from './components/CrateUsingTemplate';
import { NoPipelines } from './components/NoPipelines';
import { PipelinesActions } from './components/PipelinesActions';
import { PipelinesTable } from './components/PipelinesTable';

import { captureException } from 'src/services/sentry';
import { CreateFromTemplatesErrors } from './components/CreateFromTemplatesErrors';
import { ExportedPipeline, PipelineTableFilters } from './types';
import { formatFilter } from './utils';

const ITEMS_PER_PAGE = 10;

export const Pipelines = () => {
  const { pipelines, pagination, loading, api: pipelineApi } = useContext(PipelinesContext);
  const { selectedInstance } = useInstances();
  const { openModal, closeModal } = useContext(ModalContext);
  const { openDrawer, closeDrawer } = useDrawer();
  const snackbar = useSnackbar();
  const [sortColumn, setSortColumn] = useState('name');
  const [sortAsc, setSortAsc] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<PipelineTableFilters>([]);
  const [nameQuery, setNameQuery] = useState('');
  const { value: debouncedNameQuery } = useDebouncedValue(nameQuery, 250);
  const pipelinesApi = useBifrostApi(PipelinesApi);
  const pipelineStepsApi = useBifrostApi(PipelineStepsApi);

  const pipelinesList = useAsyncRetry(async () => {
    const { data } = await pipelinesApi.getPipelinesList({ instanceId: selectedInstance.id });
    return data;
  }, [selectedInstance]);
  useEffect(() => pipelinesList.retry(), [pipelines]);

  useEffect(() => {
    if (selectedInstance.id) {
      fetchPipelines({
        instance_id: selectedInstance.id,
        current_page: currentPage,
        page_size: ITEMS_PER_PAGE,
        sort: sortColumn as 'name' | 'enabled' | 'description' | 'schedule',
        order: sortAsc ? 'asc' : 'desc',
        filters: [
          ...filters.map(formatFilter),
          { key: 'name', condition: 'contains', exact_value: debouncedNameQuery },
        ],
      });
    }
  }, [selectedInstance, sortColumn, sortAsc, currentPage, filters, debouncedNameQuery]);

  function fetchPipelines(request: PipelineFilteredRequestBody) {
    try {
      pipelineApi.fetchPipelines(request);
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(error.message);
    }
  }

  const createNewPipeline = async (event: {
    name: string;
    schedule: string;
    description: string;
    importedConfiguration: ExportedPipeline | null;
    timeZone: string;
  }) => {
    const { name, schedule, description, importedConfiguration, timeZone } = event;
    const createdPipeline = await pipelineApi.createPipeline({
      name,
      schedule,
      description,
      instanceId: selectedInstance.id,
      time_zone: timeZone,
    });

    if (importedConfiguration?.steps?.length && importedConfiguration.fsm?.transitions?.length) {
      const orderedSteps = getOrderedPipelineSteps(importedConfiguration.steps, importedConfiguration.fsm.transitions);

      let prevStepId: string | undefined = undefined;
      for await (const step of orderedSteps) {
        const { data: createdStep } = await pipelineStepsApi.createPipelineStep({
          pipelineStepCreate: {
            name: step.name ?? '(Empty)',
            description: step.description ?? '',
            type: step.type! as any,
            configuration: step.configuration as any,
            pipeline_id: createdPipeline.id,
            previous_step_id: prevStepId,
          },
        });
        prevStepId = createdStep.id;
      }
    }
  };

  const handleAddNewPipeline = () => {
    openModal(
      <AddPipelineModal
        onCreateManually={async (event) => {
          try {
            snackbar.enqueueSuccessSnackbar('Creating pipeline...');
            await createNewPipeline(event);
            snackbar.enqueueSuccessSnackbar('Pipeline has been created');
          } catch (error) {
            snackbar.enqueueErrorSnackbar(error.message);
            captureException(error);
          } finally {
            closeModal();
          }
        }}
        onCreateUsingTemplate={async ({ template, filledConfig, name }) => {
          snackbar.enqueueSuccessSnackbar('Creating pipeline...');
          const { errorsMap } = await pipelineApi.createPipelinesFormTemplates({
            filledTemplateMap: { [template.id]: filledConfig },
            selectedTemplates: [template],
            instanceId: selectedInstance.id,
            nameByTemplateIdMap: { [template.id]: name },
          });
          if (errorsMap.size > 0) {
            snackbar.dequeue();
            errorsMap.forEach((templateError) => {
              captureException(templateError.error);
            });
            openModal(<CreateFromTemplatesErrors errorsMap={errorsMap} />, { closeable: true, size: 'auto' });
          } else {
            snackbar.enqueueSuccessSnackbar('Pipeline has been created');
            closeModal();
          }
        }}
      />,
      { size: 'auto', closeable: true },
    );
  };

  const handleCreateUsingTemplate = () => {
    openDrawer(
      <CrateUsingTemplateDrawer
        instanceId={selectedInstance.id}
        createPipelinesFormTemplates={pipelineApi.createPipelinesFormTemplates}
        onClose={closeDrawer}
      />,
      {
        size: 'auto',
        anchor: 'left',
        title: 'Pipeline module wizard',
      },
    );
  };

  const handleRefresh = () => {
    fetchPipelines({
      instance_id: selectedInstance.id,
      current_page: currentPage,
      page_size: ITEMS_PER_PAGE,
      sort: sortColumn as 'name' | 'enabled' | 'description' | 'schedule',
      order: sortAsc ? 'asc' : 'desc',
      filters: [...filters.map(formatFilter), { key: 'name', condition: 'contains', exact_value: debouncedNameQuery }],
    });
  };

  function handleSortChange(id: string) {
    if (id === sortColumn) {
      setSortAsc((asc) => !asc);
    } else {
      setSortColumn(id);
      setSortAsc(true);
    }
    setCurrentPage(0);
  }

  const handleFilterChange = (filters: PipelineTableFilters) => {
    setFilters(filters);
    setCurrentPage(0);
  };

  const handleNameQueryChange = (query: string) => {
    setNameQuery(query);
    setCurrentPage(0);
  };

  return (
    <Wrapper height="100%" width="100%" padding={`0 0 ${Tokens.ref.sizing.scale100}`} direction="column">
      <PipelinesActions
        pipelinesList={pipelinesList.value}
        filters={filters}
        onFiltersChange={handleFilterChange}
        onNewPipeline={handleAddNewPipeline}
        onCreateUsingTemplate={handleCreateUsingTemplate}
        onRefresh={handleRefresh}
        onNameQueryChanged={handleNameQueryChange}
      />
      {!loading && pipelines?.length === 0 ? (
        <NoPipelines
          filtered={filters.length > 0}
          onNewPipeline={handleAddNewPipeline}
          createUsingTemplate={handleCreateUsingTemplate}
        />
      ) : (
        <>
          <PipelinesTable
            pipelines={pipelines as any}
            loading={loading}
            sortAsc={sortAsc}
            sortColumn={sortColumn}
            refresh={handleRefresh}
            handleSort={handleSortChange}
          />
          <Pagination
            currentPage={currentPage}
            numPages={pagination.total_pages}
            onPageChange={({ nextPage }) => setCurrentPage(nextPage)}
          />
        </>
      )}
    </Wrapper>
  );
};
