import { useContext } from 'react';
import { Button, CancelIcon, CheckCircleIcon, Wrapper, useSnackbar } from '@visualfabriq/vf-ui-kit';

import { PipelinesContext } from 'src/domain/pipelines/PipelinesProvider';
import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { ConfirmModal } from 'src/components/organisms/ConfirmModal';
import { PipelineFilteredDTO } from 'src/dto/pipelines/Pipeline';

import { useUserPermissions } from 'src/domain/permissions/useUserPermissions';
import { IconButton } from 'src/components/molecules/IconButton';

import { ExecutionButton } from './ExecutionButton';
import { captureException } from 'src/services/sentry';

type Props = {
  pipeline: PipelineFilteredDTO;
  refresh: () => void;
};

export function ActionsColumn(props: Props) {
  const { pipeline, refresh } = props;
  const {
    api: { updatePipeline, executePipeline },
  } = useContext(PipelinesContext);
  const userPermission = useUserPermissions();
  const canUpdatePipeline = userPermission.has('PipelinesUpdate');

  const { openModal, closeModal } = useContext(ModalContext);
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const handleEnableToggle = async () => {
    try {
      await updatePipeline(pipeline.id, { enabled: !pipeline!.enabled });
      enqueueSuccessSnackbar(`Pipeline has been ${!pipeline!.enabled ? 'enabled' : 'disabled'}`);
      await refresh();
    } catch (error) {
      enqueueErrorSnackbar(error.message);
    } finally {
      closeModal();
    }
  };

  const handleExecute = async () => {
    try {
      await executePipeline(pipeline!.id);
      enqueueSuccessSnackbar('Pipeline has been executed');
      await refresh();
    } catch (error) {
      enqueueErrorSnackbar(error.message);
      captureException(error);
    }
  };

  const updatePipelineBlocked = async (args: { blocked: boolean; blocked_details: string }) => {
    const { blocked, blocked_details } = args;
    try {
      await updatePipeline(pipeline.id, { blocked, blocked_details });
      enqueueSuccessSnackbar(`Pipeline has been ${blocked ? 'blocked' : 'unblocked'}`);
      await refresh();
    } catch (error) {
      enqueueErrorSnackbar(error.message);
    }
  };

  const handleBlockToggle = async () => {
    const modal = pipeline?.blocked ? (
      <ConfirmModal
        question={`Are you sure you want to unblock ${pipeline?.name}?`}
        onConfirm={() => {
          try {
            updatePipelineBlocked({ blocked: false, blocked_details: '' });
          } catch (error) {
            enqueueErrorSnackbar(error.message);
            captureException(error);
          } finally {
            closeModal();
          }
        }}
        onCancel={closeModal}
      />
    ) : (
      <ConfirmModal
        question={`Are you sure you want to block ${pipeline?.name}?`}
        onConfirm={() => {
          try {
            updatePipelineBlocked({ blocked: true, blocked_details: '' });
          } catch (error) {
            enqueueErrorSnackbar(error.message);
            captureException(error);
          } finally {
            closeModal();
          }
        }}
        onCancel={closeModal}
      />
    );

    openModal(modal);
  };

  return (
    <Wrapper gap="200" marginBlock="400" width="160px">
      <IconButton
        kind="secondary"
        icon={pipeline?.enabled ? <CancelIcon /> : <CheckCircleIcon />}
        title={pipeline?.enabled ? 'Disable' : 'Enable'}
        disabled={!pipeline && !canUpdatePipeline}
        onClick={handleEnableToggle}
        placement="top"
      />
      <ExecutionButton pipeline={pipeline} disabled={!canUpdatePipeline} onExecute={handleExecute} />
      <Button disabled={!pipeline && !canUpdatePipeline} kind="secondary" onClick={handleBlockToggle}>
        {pipeline?.blocked ? 'Unblock' : 'Block'}
      </Button>
    </Wrapper>
  );
}
