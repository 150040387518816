import { useEffect, useState } from 'react';
import { TableBuilder, TableBuilderColumn, NoContent } from '@visualfabriq/vf-ui-kit';

import { HistoryColumn } from './HistoryColumn';
import { ActionsColumn } from './ActionsColumn';
import { ConfigurationColumn } from './ConfigurationColumn';
import { NameColumn } from './NameColumn';
import { PipelineExecution } from 'src/api-new/bifrost';
import { getTimeZoneName } from 'src/utils/date/getTimeZoneDisplayName';
import { PipelineStatus } from 'src/domain/pipelines/types';
import { DayOfWeek, replaceCron } from 'src/utils/awsCron/utils';
import { PipelineFilteredDTO } from 'src/dto/pipelines/Pipeline';

type PipelinesTableProps = {
  pipelines: PipelineFilteredDTO[];
  loading: boolean;
  sortColumn: string;
  sortAsc: boolean;
  handleSort: (id: string) => void;
  refresh: () => void;
};

export function PipelinesTable(props: PipelinesTableProps) {
  const { pipelines, loading, sortColumn, sortAsc, handleSort, refresh } = props;
  const [tableData, setTableData] = useState<TableData>([]);

  useEffect(() => {
    setTableData(getTableData(pipelines));
  }, [pipelines]);

  function getTableData(pipelines: PipelineFilteredDTO[]): TableData {
    return pipelines.map<TableData[0]>((pipeline) => {
      const timeZoneShort = getTimeZoneName(pipeline.time_zone ?? 'GMT', 'shortOffset');
      return {
        id: pipeline.id,
        name: pipeline.name ?? '(Empty)',
        status: pipeline.enabled ? 'Enabled' : 'Disabled',
        executions: pipeline.executions,
        description: pipeline.description ?? '',
        schedule: pipeline.schedule
          ? `${replaceCron(pipeline.schedule!, { dow: DayOfWeek.awsToStandard })} (${timeZoneShort})`
          : '(Empty)',
        blocked: pipeline.blocked ?? false,
        _pipeline: pipeline,
      };
    });
  }

  if (!loading && !pipelines.length) {
    return <NoContent isFilterApplied />;
  }

  return (
    <TableBuilder
      isLoading={loading}
      overrides={{
        Root: { style: { maxHeight: '90%', overflowY: 'auto' } },
        TableBodyRow: {
          style: ({ $row, $theme }) => {
            return {
              backgroundColor: $row._pipeline.blocked
                ? $theme.colors.backgroundSecondary
                : $theme.colors.backgroundPrimary,
            };
          },
        },
      }}
      data={tableData}
      sortColumn={sortColumn}
      sortOrder={sortAsc ? 'ASC' : 'DESC'}
      onSort={handleSort}
    >
      <TableBuilderColumn id="name" header="Name" sortable>
        {(row: Row) => <NameColumn name={row.name} blocked={row.blocked} />}
      </TableBuilderColumn>
      <TableBuilderColumn id="actions" header="Actions">
        {(row: Row) => <ActionsColumn pipeline={row._pipeline} refresh={refresh} />}
      </TableBuilderColumn>
      <TableBuilderColumn id="enabled" header="Status" sortable>
        {(row: Row) => row.status}
      </TableBuilderColumn>
      <TableBuilderColumn id="history" header="History">
        {(row: Row) => <HistoryColumn pipeline={row._pipeline} />}
      </TableBuilderColumn>
      <TableBuilderColumn id="description" header="Description" sortable>
        {(row: Row) => row.description}
      </TableBuilderColumn>
      <TableBuilderColumn id="schedule" header="Schedule" sortable>
        {(row: Row) => row.schedule}
      </TableBuilderColumn>
      <TableBuilderColumn id="configuration">
        {(row: Row) => <ConfigurationColumn pipeline={row._pipeline} />}
      </TableBuilderColumn>
    </TableBuilder>
  );
}

type Row = {
  id: string;
  name: string;
  status: PipelineStatus;
  executions: PipelineExecution[];
  description: string;
  schedule: string;
  blocked: boolean;
  _pipeline: PipelineFilteredDTO;
};

type TableData = Row[];
