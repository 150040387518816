import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  PaginationParameters,
  PipelineExecutionOutput,
  PipelineExecutionsApi,
  PipelineFilteredRequestBody,
  PipelinesApi,
  PipelineStepCreate,
  PipelineStepsApi,
  PipelineTemplate,
  PipelineUpdate,
} from 'src/api-new/bifrost';
import * as PipelinesAPI from 'src/api/pipelines';

import { CreatePipelineDTO, PipelineDTO, PipelineFilteredDTO } from 'src/dto/pipelines/Pipeline';

import { useBifrostApi } from 'src/services/useBifrostApi';
import { useUser } from 'src/services/useUser';
import { FilledConfig, FilledTemplateEvent } from '../types';
import { getDataProcessingStep, getDataStagingStep } from './helpers';
import { CreatePipelineUsingTemplateError } from './types';

type PipelinesState = {
  pipelines: PipelineDTO[];
  selectedPipeline: PipelineDTO;
  pagination: PaginationParameters;
  loading: boolean;
  api: {
    fetchPipelines: (request: PipelineFilteredRequestBody) => Promise<void>;
    createPipeline: (args: CreatePipelineDTO) => Promise<PipelineDTO>;
    deletePipeline: (id: string) => Promise<void>;
    updatePipeline: (pipelineId: string, pipelineUpdate: PipelineUpdate) => Promise<void>;
    executePipeline: (id: string) => Promise<void>;
    stopExecution: (executionId: string) => Promise<PipelineExecutionOutput>;
    createPipelinesFormTemplates: (
      args: FilledTemplateEvent & { instanceId: string },
    ) => Promise<{ errorsMap: Map<string, CreatePipelineUsingTemplateError> }>;
  };
};

export const PipelinesContext = React.createContext<PipelinesState>(null as unknown as PipelinesState);

function usePipelines(): PipelinesState {
  const pipelineExecutionsApi = useBifrostApi(PipelineExecutionsApi);
  const pipelinesApi = useBifrostApi(PipelinesApi);
  const pipelineStepsApi = useBifrostApi(PipelineStepsApi);
  const { pipelineId } = useParams();
  const user = useUser();
  const [pipelines, setPipelines] = useState<PipelineFilteredDTO[]>([]);
  const [pagination, setPagination] = useState<PaginationParameters>({ total_pages: 1 });
  const [loading, setLoading] = useState(true);

  const selectedPipeline = useMemo(() => pipelines.find(({ id }) => id === pipelineId)!, [pipelines, pipelineId]);

  const getPipelines = async (request: PipelineFilteredRequestBody) => {
    setLoading(true);
    const {
      data: { data, pagination },
    } = await pipelinesApi.getFilteredPipelines({ pipelineFilteredRequestBody: request });
    setPipelines(data as any);
    setPagination(pagination);
    setLoading(false);
  };

  const createPipeline = async (args: CreatePipelineDTO) => {
    const { name, schedule, description = '', instanceId, time_zone } = args;
    const createdPipeline = await PipelinesAPI.createPipeline({
      instanceId,
      name,
      schedule,
      description,
      owner_email: user.email,
      time_zone,
      getAccessToken: user.getAccessToken,
    });

    if (createdPipeline) {
      setPipelines((pipelines) => [...pipelines, { ...createdPipeline, executions: [] }]);
    }

    return createdPipeline;
  };

  const createPipelineFromTemplate = async (
    template: PipelineTemplate,
    name: string | undefined,
    filledConfig: FilledConfig,
    instanceId: string,
  ) => {
    const pipeline = await createPipeline({ name: name || template.label, schedule: '0 12 * * ? *', instanceId });
    let prevStepId: string | null = null;
    for (const stepConfiguration of template.steps_configuration) {
      let stepConfig: Partial<PipelineStepCreate>;
      switch (stepConfiguration.type) {
        case 'data_processing': {
          const filledArgs = filledConfig[stepConfiguration.id];
          stepConfig = getDataProcessingStep(stepConfiguration, pipeline.id, filledArgs);
          break;
        }
        case 'data_staging': {
          stepConfig = getDataStagingStep(stepConfiguration, pipeline.id, instanceId);
          break;
        }
      }
      const { data: addedStep } = await pipelineStepsApi.createPipelineStep({
        pipelineStepCreate: {
          ...stepConfig,
          ...(prevStepId ? { previous_step_id: prevStepId } : {}),
        } as PipelineStepCreate,
      });
      prevStepId = addedStep.id;
    }
  };

  const createPipelinesFormTemplates = async ({
    filledTemplateMap,
    selectedTemplates,
    instanceId,
    nameByTemplateIdMap,
  }: FilledTemplateEvent & { instanceId: string }) => {
    const errorsMap: Map<string, CreatePipelineUsingTemplateError> = new Map();
    for await (const template of selectedTemplates) {
      const filledConfig = filledTemplateMap[template.id];
      try {
        await createPipelineFromTemplate(template, nameByTemplateIdMap[template.id], filledConfig, instanceId);
      } catch (error) {
        errorsMap.set(template.id, { template, error });
      }
    }

    return { errorsMap };
  };

  const deletePipeline = async (id: string) => {
    await PipelinesAPI.deletePipeline(id, user.getAccessToken);
    setPipelines(pipelines.filter((pipeline) => pipeline.id !== id));
  };

  const updatePipeline = async (pipelineId: string, pipelineUpdate: PipelineUpdate) => {
    const { data } = await pipelinesApi.updatePipeline({ pipelineId, pipelineUpdate });
    const updatedPipeline = data as any as PipelineDTO;
    if (updatedPipeline) {
      setPipelines(
        pipelines.map((pipeline) =>
          pipeline.id === updatedPipeline.id ? { ...updatedPipeline, executions: pipeline.executions } : pipeline,
        ),
      );
    }
  };

  const executePipeline = async (pipelineId: string) => {
    const result = await pipelineExecutionsApi.createPipelineExecution({
      pipelineExecutionBase: { pipeline_id: pipelineId },
    });
    const { data: newExecution } = result;

    setPipelines(
      pipelines.map((pipeline) =>
        pipeline.id === pipelineId ? { ...pipeline, executions: [newExecution, ...pipeline.executions] } : pipeline,
      ),
    );
  };

  const stopExecution = async (executionId: string) => {
    const { data: stoppedExecution } = await pipelineExecutionsApi.stopPipelineExecution({
      pipelineExecutionId: executionId,
    });

    setPipelines(
      pipelines.map((pipeline) =>
        pipeline.id === stoppedExecution.pipeline_id
          ? {
              ...pipeline,
              executions: pipeline.executions.map((execution) =>
                execution.id === stoppedExecution.id ? stoppedExecution : execution,
              ),
            }
          : pipeline,
      ),
    );

    return stoppedExecution;
  };

  return {
    pipelines,
    pagination,
    selectedPipeline,
    loading,
    api: {
      createPipeline,
      deletePipeline,
      updatePipeline,
      executePipeline,
      fetchPipelines: getPipelines,
      stopExecution,
      createPipelinesFormTemplates,
    },
  };
}

export function PipelinesProvider(props: { children?: any }) {
  return <PipelinesContext.Provider value={usePipelines()}>{props.children}</PipelinesContext.Provider>;
}
