import { useEffect, useState } from 'react';
import { Button, Tab, Tabs, Wrapper, useSnackbar, useStyletron } from '@visualfabriq/vf-ui-kit';

import { PipelineStepType } from 'src/dto/PipelineStep/PipelineStepType';
import { DataStagingConfigInputType, PipelineTemplate, PipelineTemplatesApi } from 'src/api-new/bifrost';
import { useBifrostApi } from 'src/services/useBifrostApi';
import * as StepLayout from '../StepLayout';
import {
  FilledTemplateEvent,
  FilledTemplateMap,
  NameByTemplateIdMap,
  TemplateArgType,
} from 'src/domain/pipelines/types';
import { CreateCustomTemplateState } from '../types';
import { Template } from '../../Template';
import { captureException } from '@sentry/browser';

type Props = {
  state: CreateCustomTemplateState;
  onFinish: (event: FilledTemplateEvent, startNew: boolean) => void;
  onPrev: () => void;
};
export function FillTemplateStep(props: Props) {
  const {
    state: { selectedTemplatesIds },
    onFinish,
    onPrev,
  } = props;
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<React.Key>(0);
  const [templates, setTemplates] = useState<PipelineTemplate[]>([]);
  const [filledConfigMap, setFilledConfigMap] = useState<FilledTemplateMap>({});
  const [nameByTemplateIdMap, setNameByTemplateIdMap] = useState<NameByTemplateIdMap>({});
  const [validationState, setValidationState] = useState<ValidationState | null>(null);
  const { enqueueErrorSnackbar } = useSnackbar();
  const pipelineTemplatesApi = useBifrostApi(PipelineTemplatesApi);
  const [css] = useStyletron();

  const isValid = validationState
    ? Array.from(Object.values(validationState))
        .map((state) => Object.values(state))
        .flat()
        .every(Boolean)
    : false;

  const handleFinish = () =>
    onFinish({ selectedTemplates: templates, filledTemplateMap: filledConfigMap, nameByTemplateIdMap }, false);
  const handleFinishAndStartAnother = () =>
    onFinish({ selectedTemplates: templates, filledTemplateMap: filledConfigMap, nameByTemplateIdMap }, true);

  const handleFromChange = (event: {
    args: Record<string, TemplateArgType>;
    templateId: string;
    stepConfigurationId: string;
  }) => {
    const { args, templateId, stepConfigurationId } = event;

    setFilledConfigMap({
      ...filledConfigMap,
      [templateId]: {
        ...filledConfigMap[templateId],
        [stepConfigurationId]: args,
      },
    });
  };

  const handleArgsFormValidate = (event: { templateId: string; stepConfigurationId: string; isValid: boolean }) => {
    if (!validationState) {
      throw Error(`Args Validation state is empty`);
    }

    const { templateId, stepConfigurationId, isValid } = event;
    setValidationState((state) => ({
      ...state,
      [templateId]: {
        ...state![templateId],
        [stepConfigurationId]: isValid,
      },
    }));
  };

  const handleNameChange = (name: string, templateId: string) => {
    setNameByTemplateIdMap((state) => ({ ...state, [templateId]: name }));
  };

  useEffect(() => {
    async function fetchTemplatesArgs(templatesIds: string[]) {
      try {
        setLoading(true);
        const { data: templates } = await pipelineTemplatesApi.getPipelineTemplates({ id: templatesIds });
        setTemplates(templates);
        setFilledConfigMap(
          templates.reduce<FilledTemplateMap>((map, template) => {
            return {
              ...map,
              [template.id]: template.steps_configuration.reduce((configMap, config) => {
                if (config.type === PipelineStepType.data_processing) {
                  return {
                    ...configMap,
                    [config.id]: config.args.reduce<Record<string, string>>((initData, templateArg) => {
                      const value = templateArg.default_value ?? '';
                      return { ...initData, [templateArg.key]: value };
                    }, {}),
                  };
                } else {
                  return configMap;
                }
              }, {}),
            };
          }, {}),
        );
        setValidationState(
          templates.reduce<ValidationState>((map, template) => {
            return {
              ...map,
              [template.id]: template.steps_configuration.reduce(
                (configMap, config) => ({
                  ...configMap,
                  [config.id]: [DataStagingConfigInputType.DataStaging].includes(config.type),
                }),
                {},
              ),
            };
          }, {}),
        );
      } catch (error) {
        captureException(error);
        enqueueErrorSnackbar(error.message);
      } finally {
        setLoading(false);
      }
    }

    if (selectedTemplatesIds.length) {
      fetchTemplatesArgs(selectedTemplatesIds);
    }
  }, [selectedTemplatesIds]);

  if (loading) {
    return <StepLayout.StepLoading title="Configure pipelines" />;
  }

  return (
    <StepLayout.StepLayout>
      <StepLayout.StepHeader title="Configure pipelines" />
      <StepLayout.StepBody>
        <Tabs
          activeKey={selectedTab}
          orientation="vertical"
          onChange={({ activeKey }) => {
            setSelectedTab(activeKey);
          }}
        >
          {templates.map((template) => (
            <Tab key={template.id} title={template.label}>
              <Template
                name={nameByTemplateIdMap[template.id]}
                onNameChange={handleNameChange}
                template={template}
                filledConfigMap={filledConfigMap}
                onChange={handleFromChange}
                onValidateChange={handleArgsFormValidate}
                className={css({ width: '30rem' })}
              />
            </Tab>
          ))}
        </Tabs>
      </StepLayout.StepBody>
      <StepLayout.StepFooter>
        <Wrapper width="100%" justifyContent="end" gap="200" marginBlock="200">
          <Button size="large" onClick={() => onPrev()}>
            Back
          </Button>
          <Button size="large" disabled={!isValid} onClick={handleFinish}>
            Finish
          </Button>
          <Button size="large" disabled={!isValid} onClick={handleFinishAndStartAnother}>
            Finish, and start another wizard
          </Button>
        </Wrapper>
      </StepLayout.StepFooter>
    </StepLayout.StepLayout>
  );
}

type TemplateId = string;
type StepConfigId = string;

type ValidationState = Record<TemplateId, Record<StepConfigId, boolean>>;
