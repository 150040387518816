import { CancelIcon, CheckCircleIcon, ErrorIcon, ProductLifeCycleIcon } from '@visualfabriq/vf-ui-kit';
import { COLOR } from '@visualfabriq/vf-ui-kit/dist/components/atoms/content/tag/types';
import { FSMState, PipelineFilteredListRequestBodyFiltersInner } from 'src/api-new/bifrost';
import { EXECUTIONS_STATE_DISPLAY_NAMES } from 'src/domain/pipelines/constants';
import { StateDisplayName } from 'src/domain/pipelines/types';
import { PipelineTableFilters } from './types';

export function getStatusColor(status: FSMState): COLOR[keyof COLOR] {
  switch (status) {
    case FSMState.Failed:
    case FSMState.Stopped:
      return 'red';

    case FSMState.Finished:
      return 'green';

    case FSMState.NoAction:
      return 'blue';

    default:
      return 'orange';
  }
}

export function getExecutionStatusIcon(state: FSMState): typeof CheckCircleIcon {
  switch (state) {
    case FSMState.Finished:
      return CheckCircleIcon;
    case FSMState.Failed:
      return ErrorIcon;
    case FSMState.Started:
    case FSMState.RetrievingFromHttp:
    case FSMState.RetrievingFromSftp:
    case FSMState.SendingToAzure:
    case FSMState.SendingToHttp:
    case FSMState.SendingToSftp:
    case FSMState.StagingData:
    case FSMState.MovingFiles:
    case FSMState.ProcessingData:
    case FSMState.RetrievingFromAzure:
      return ProductLifeCycleIcon;

    case FSMState.NoAction:
      return CheckCircleIcon;

    case FSMState.Stopped:
    case FSMState.Stopping:
      return CancelIcon;
  }
}

export function getStatusDisplayName(state: FSMState): StateDisplayName {
  switch (state) {
    case FSMState.ProcessingData:
      return 'Processing';
    case FSMState.Finished:
      return 'Successful';
    case FSMState.Failed:
      return 'Failed';
    case FSMState.Started:
      return 'Started';
    case FSMState.RetrievingFromHttp:
    case FSMState.RetrievingFromSftp:
    case FSMState.RetrievingFromAzure:
      return 'Retrieving';
    case FSMState.SendingToAzure:
    case FSMState.SendingToHttp:
    case FSMState.SendingToSftp:
      return 'Sending';
    case FSMState.StagingData:
      return 'Staging';
    case FSMState.MovingFiles:
      return 'Moving';
    case FSMState.NoAction:
      return 'No Action';
    case FSMState.Stopped:
      return 'Stopped';
    case FSMState.Stopping:
      return 'Stopping';
  }
}

export function getStatesByDisplayName(displayName: StateDisplayName): FSMState[] {
  switch (displayName) {
    case 'Processing':
      return [FSMState.ProcessingData];
    case 'Successful':
      return [FSMState.Finished];
    case 'Failed':
      return [FSMState.Failed];
    case 'Started':
      return [FSMState.Started];
    case 'Retrieving':
      return [FSMState.RetrievingFromHttp, FSMState.RetrievingFromSftp, FSMState.RetrievingFromAzure];
    case 'Sending':
      return [FSMState.SendingToAzure, FSMState.SendingToHttp, FSMState.SendingToSftp];
    case 'Staging':
      return [FSMState.StagingData];
    case 'Moving':
      return [FSMState.MovingFiles];
    case 'No Action':
      return [FSMState.NoAction];
    case 'Stopped':
      return [FSMState.Stopped];
    case 'Stopping':
      return [FSMState.Stopping];
  }
}

export function isExecutionDisplayName(value: string): value is StateDisplayName {
  return EXECUTIONS_STATE_DISPLAY_NAMES.includes(value);
}

export function formatFilter(filter: PipelineTableFilters[number]): PipelineFilteredListRequestBodyFiltersInner {
  return filter.key === 'enabled' ? { ...filter, exact_value: filter.exact_value === 'Enabled' } : filter;
}
