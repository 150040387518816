import { useEffect, useMemo, useState } from 'react';
import { FormControl, Select } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { DefaultSettingsDataStaging, DefaultSettingsDataStatingApi } from 'src/api-new/bifrost';

type Props = {
  value: string;
  onChange: (
    value: string,
    defaults:
      | { updateKey: string; mandatoryKeys: string; transformations: DataStagingStep.TransformationModel[] }
      | undefined,
  ) => void;
};

export function TargetSelectionSelect(props: Props) {
  const { value, onChange } = props;
  const defaultSettingsDataStatingApi = useBifrostApi(DefaultSettingsDataStatingApi);
  const [defaultSettingsDataStaging, setDefaultSettingsDataStaging] = useState<DefaultSettingsDataStaging[]>([]);

  useEffect(() => {
    const updateDefaultSettingsDataStaging = async () => {
      const { data } = await defaultSettingsDataStatingApi.getDefaultSettingsDataStagings();
      setDefaultSettingsDataStaging(data);
    };

    updateDefaultSettingsDataStaging();
  }, []);

  const tableNameOptions = useMemo(
    () =>
      defaultSettingsDataStaging.map((defaultSetting) => ({
        id: defaultSetting.id ?? undefined,
        label: defaultSetting.table,
      })),
    [defaultSettingsDataStaging],
  );

  const defaultSettingsMap = useMemo<Map<string, DefaultSettingsDataStaging>>(
    () =>
      defaultSettingsDataStaging.reduce(
        (defaultSettingsMap, defaultSettings) => defaultSettingsMap.set(defaultSettings.id, defaultSettings),
        new Map(),
      ),
    [defaultSettingsDataStaging],
  );

  const handleTargetCollectionChange: React.ComponentProps<typeof Select>['onChange'] = (event) => {
    if (event.type !== 'select') return;
    const targetCollection = event.value[0]?.label?.toString() ?? '';
    const defaultSettings = defaultSettingsMap.get(event.value[0]?.id as string);
    onChange(
      targetCollection,
      defaultSettings && {
        updateKey: (defaultSettings.update_keys || []).join(', ') || '',
        mandatoryKeys: (defaultSettings.mandatory_keys || []).join(', ') || '',
        transformations: defaultSettings.transformations as DataStagingStep.TransformationModel[],
      },
    );
  };

  return (
    <FormControl label="Target Collection" required>
      <Select
        options={tableNameOptions}
        value={[{ id: value, label: value }]}
        onChange={handleTargetCollectionChange}
        maxDropdownHeight="300px"
        creatable
        searchable
      />
    </FormControl>
  );
}
