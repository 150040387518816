import { Button, ChevronLeftIcon, ChevronRightIcon, LabelSmall, Wrapper } from '@visualfabriq/vf-ui-kit';
import { BasicProps, TestAttributesProps } from '@visualfabriq/vf-ui-kit/dist/shared/types';
import { Size } from '@visualfabriq/vf-ui-kit/dist/themes/types';
import { CSSProperties } from 'react';
import { Paginatable } from 'src/components/hooks';

export interface WrapperProps extends BasicProps, TestAttributesProps {
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  direction?: CSSProperties['flexDirection'];
  boxSizing?: CSSProperties['boxSizing'];
  wrap?: CSSProperties['flexWrap'];
  width?: Size;
  height?: Size;
  padding?: Size;
  paddingBlock?: Size;
  paddingInline?: Size;
  margin?: Size;
  marginBlock?: Size;
  marginInline?: Size;
  gap?: Size;
  rowGap?: Size;
  columnGap?: Size;
}

type PaginationProps = Pick<Paginatable<any>, 'next' | 'previous' | 'index' | 'pages'> & Omit<WrapperProps, 'children'>;

type Props = {
  currentPageItemsCount: number;
  skip: number;
  size: number;
  onPrev: () => void;
  onNext: () => void;
  disabled?: boolean;
  className?: string;
};

export function ListPagination(props: Props) {
  const { currentPageItemsCount, skip, size, onNext, onPrev, className, disabled } = props;

  const cantPrev = skip === 0;
  const cantNext = currentPageItemsCount !== size;

  return (
    <Wrapper justifyContent="space-evenly" alignItems="end" className={className}>
      <Button disabled={cantPrev || disabled} kind="tertiary" startEnhancer={ChevronLeftIcon} onClick={onPrev} />
      <LabelSmall>
        {skip + 1} to {skip + currentPageItemsCount}
      </LabelSmall>
      <Button disabled={cantNext || disabled} kind="tertiary" startEnhancer={ChevronRightIcon} onClick={onNext} />
    </Wrapper>
  );
}

export function Pagination({ index, next, previous, pages, ...props }: PaginationProps) {
  return (
    <Wrapper justifyContent="space-evenly" alignItems="end" {...props}>
      <Button disabled={index === 1} kind="tertiary" startEnhancer={ChevronLeftIcon} onClick={previous} />
      <LabelSmall>
        {index} of {pages}
      </LabelSmall>
      <Button disabled={index === pages} kind="tertiary" startEnhancer={ChevronRightIcon} onClick={next} />
    </Wrapper>
  );
}
