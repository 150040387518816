import { Button, FilterPopover, InsightsIcon, useStyletron, Input, Wrapper } from '@visualfabriq/vf-ui-kit';

import { PipelineListItem } from 'src/api-new/bifrost';
import { useUserPermissions } from 'src/domain/permissions/useUserPermissions';
import { PipelineTableFilters } from '../../types';
import { PIPELINE_STATUSES } from 'src/domain/pipelines/constants';
import { Link } from 'react-router-dom';
import { pathConstants } from 'src/routes';

type Props = {
  pipelinesList: PipelineListItem[] | undefined;
  onNewPipeline: () => void;
  onCreateUsingTemplate: () => void;
  onRefresh: () => void;
  filters: PipelineTableFilters;
  onFiltersChange: (filters: PipelineTableFilters) => void;
  onNameQueryChanged: (query: string) => void;
};

export function PipelinesActions(props: Props) {
  const { pipelinesList, onNewPipeline, onCreateUsingTemplate, onRefresh, onFiltersChange, onNameQueryChanged } = props;
  const userPermission = useUserPermissions();
  const canCreatePipeline = userPermission.has('PipelinesCreate');
  const [_, theme] = useStyletron();

  return (
    <Wrapper marginBlock={`${theme.sizing.scale400} 0`} gap={200}>
      <Button disabled={!canCreatePipeline} onClick={onNewPipeline}>
        Create single Pipeline
      </Button>
      <Button disabled={!canCreatePipeline} onClick={onCreateUsingTemplate}>
        Pipeline module wizard
      </Button>
      <Wrapper width="400px">
        <Input placeholder="Search by name..." onChange={(event) => onNameQueryChanged(event.target.value)} clearable />
      </Wrapper>

      {pipelinesList && (
        <FilterPopover
          onApply={(event) => onFiltersChange(event as PipelineTableFilters)}
          filterItems={[
            { key: 'step_name', dimension: 'Step Name', filter_type: 'text', conditions: ['contains'] },
            {
              key: 'id',
              dimension: 'Pipeline',
              filter_type: 'select',
              conditions: ['nin', 'in'],
              value_list: pipelinesList.map((p) => ({ id: p.id, name: p.name })),
            },
            {
              key: 'enabled',
              dimension: 'Pipeline Status',
              filter_type: 'select',
              conditions: ['exact'],
              value_list: PIPELINE_STATUSES.map((status) => ({ id: status, name: status })),
            },
          ]}
          itemIdKey="key"
        />
      )}
      <div style={{ marginLeft: 'auto' }}>
        <Wrapper gap={200}>
          <Button onClick={onRefresh}>Refresh</Button>
          <Link to={`../${pathConstants.DASHBOARD.root}/${pathConstants.DASHBOARD.pipelineSchedule}`}>
            <Button startEnhancer={<InsightsIcon color={theme.colors.accent} />} kind="tertiary">
              Schedule Dashboard
            </Button>
          </Link>
        </Wrapper>
      </div>
    </Wrapper>
  );
}
